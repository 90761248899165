import { doc, deleteDoc } from 'firebase/firestore'

const state = () => ({
  myEvents: null,
  eventsInitialized: false,
  requiresRsvp: null,
  alreadyRsvped: null
})

const getters = {
  myEvents: state => state.myEvents,
  eventsInitialized: state => state.eventsInitialized,
  requiresRsvp: state => state.requiresRsvp,
  alreadyRsvped: state => state.alreadyRsvped,
  eventHeaders: () => (adminView, targetAuthorData) => {
    const headers = []

    headers.push({
      text: 'Time',
      value: 'time',
      groupable: false,
      width: '16%'
    },
    {
      text: 'Date',
      value: 'date'
    },
    {
      text: 'Event',
      value: 'description',
      groupable: false
    },
    {
      text: 'Location',
      value: 'location',
      groupable: false
    })

    if (adminView) {
      headers.push(
        {
          text: 'Tags',
          value: 'tags',
          sortable: false,
          groupable: false
        },
        {
          text: 'RSVP Tags',
          value: 'rsvpTags',
          sortable: false,
          groupable: false
        }
      )
    }

    if (targetAuthorData) {
      headers.push({
        text: 'RSVP',
        value: 'rsvpData.requiresRsvp',
        groupable: false,
        class: 'rsvp-col'
      })
    }
    if (targetAuthorData?.tags?.includes('plus-one')) {
      headers.push({
        text: 'RSVP +1',
        value: 'rsvpData.requiresPlusOneRsvp',
        groupable: false,
        class: 'rsvp-col'
      })
    }

    if (adminView) {
      headers.push({
        text: 'Actions',
        value: 'actions',
        sortable: false,
        groupable: false,
        width: '9%'
      })
    }
    return headers
  }
}

const mutations = {
  setMyEvents (state, val) {
    console.log('Setting my events to', val)
    state.myEvents = val
    state.requiresRsvp = false
    state.myEvents.forEach((event) => {
      if (
        (event.rsvpData?.requiresRsvp && event.rsvpData?.rsvpConfirmed) &&
        ((event.rsvpData?.requiresPlusOneRsvp && event.rsvpData?.plusOneRsvpConfirmed) || !event.rsvpData?.requiresPlusOneRsvp)
      ) {
        state.alreadyRsvped = true
      }
      if (
        (event.rsvpData?.requiresRsvp && !event.rsvpData?.rsvpConfirmed) ||
        (event.rsvpData?.requiresPlusOneRsvp && !event.rsvpData?.plusOneRsvpConfirmed)
      ) {
        // If any event requires RSVP, then the whole author requires RSVP
        state.requiresRsvp = true
      }
    })
    state.eventsInitialized = true
  }
}

const actions = {
  async firestoreDeleteEvent ({ rootState }, eventId) {
    await deleteDoc(doc(rootState.firestore, 'events', eventId))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
