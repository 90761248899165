<template>
  <!-- :style="{background: $vuetify.theme.themes.light.background}" -->
  <v-app>
    <template v-if="!shareQuery">
      <TheNavigation />
      <TheNavigationDrawer />
    </template>
    <v-main>
      <router-view/>
    </v-main>
    <v-btn
      v-if="userRole === 'admin'"
      fab
      class="add-event-button"
      color="accent"
      raised
      bottom
      right
      fixed
      @click="$root.$emit('showEventEditor')"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <TheEventEditor v-if="userRole === 'admin'" />
    <TheAuthorEditor />
    <TheAuthorImporter />
    <TheLocationEditor />

    <div class="notifications">
      <v-snackbar
        v-if="globalNotification"
        v-model="showNotification"
        :timeout="globalNotification && globalNotification.type === 'error' ? -1 : 5000"
        :color="globalNotification && globalNotification.type === 'error' ? 'error' : null"
      >
        <v-icon
          v-if="globalNotification && globalNotification.type === 'error'"
          left
        >
          mdi-alert
        </v-icon>
        {{ globalNotification.val }}
        <template v-slot:action="{ attrs }">
          <v-btn text fab small v-bind="attrs" @click="showNotification = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-app>
</template>

<script>
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import TheNavigation from '@/components/TheNavigation'
import TheNavigationDrawer from '@/components/TheNavigationDrawer'
import TheEventEditor from '@/components/TheEventEditor'
import TheAuthorEditor from '@/components/TheAuthorEditor'
import TheAuthorImporter from '@/components/TheAuthorImporter'
import TheLocationEditor from '@/components/TheLocationEditor'

export default {
  name: 'App',
  components: {
    TheNavigation,
    TheNavigationDrawer,
    TheEventEditor,
    TheAuthorEditor,
    TheAuthorImporter,
    TheLocationEditor
  },
  async created () {
    await this.globalSettingsListener()
    await this.locationsListener()
    await this.userAuthCheck()
    this.checkForSignInLink()
  },
  data: () => ({
    showNotification: false
  }),
  computed: {
    ...mapGetters(['userRole']),
    ...mapState(['globalNotification', 'shareQuery'])
  },
  methods: {
    ...mapMutations(['setGlobalNotification']),
    ...mapActions(['globalSettingsListener', 'locationsListener', 'userAuthCheck']),

    /**
     * Called when the app is created. Used to see if the URL contains a sign in link,
     * and log them in if applicable.
     */
    async checkForSignInLink () {
      const auth = getAuth()
      if (isSignInWithEmailLink(auth, window.location.href)) {
        const email = window.localStorage.getItem('emailForSignIn')
        if (email) {
          try {
            await signInWithEmailLink(auth, email, window.location.href)
            window.localStorage.removeItem('emailForSignIn')
            await this.userAuthCheck()
          } catch (err) {
            console.log(err)
            this.setGlobalNotification({ val: err.message, type: 'error' })
          }
        }
      }
    }
  },

  watch: {
    globalNotification: function (val) {
      console.log('Notification:', val)
      this.showNotification = true
    }
  }
}
</script>

<style lang="scss">
$body-font: 'freight-sans-pro', sans-serif;
$title-font: 'adobe-caslon-pro', serif;

.v-application {
  font-family: $body-font;

  .site-title {
    a {
      color: white;
      text-decoration: none;
    }
  }
}
.error--text a {
  color: inherit !important;
}
.container {
  max-width: 1000px;
}

pre {
  font-family: inherit;
}
.v-btn.v-size--large {
  min-width: 160px !important;
}
.v-data-table {
  .formatted {
    white-space: pre-wrap;
  }
}
@media print {
  .v-main {
    padding-top: 0 !important;
  }
  .schedule-views {
    display: none !important;
  }
  .rsvp-col {
    display: none !important;
  }
  .add-event-button {
    display: none !important;
  }
  .v-data-table td {
    font-size: 12px !important;

    &.text-start:first-child {
      white-space: nowrap;
    }
  }
  .v-data-footer {
    display: none !important;
  }
}
</style>
