<template>
  <v-dialog v-model="shown" width="700">
    <v-card>
      <v-card-title class="mb-5 primary white--text">
        <h2 class="my-5">Update {{ targetAuthorData.fullName }}</h2>
      </v-card-title>
      <v-card-text>
        <AuthorEditorFields v-model="targetAuthorData" :targetAuthor="targetAuthorData" />
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="updateAuthor"
          color="accent"
          large
          block
        >
          Update Author
          <v-icon right>mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { doc, updateDoc } from 'firebase/firestore'
import AuthorEditorFields from '@/components/AuthorEditorFields'

export default {
  components: {
    AuthorEditorFields
  },
  data: () => ({
    targetAuthorId: null,
    targetAuthorData: {},
    shown: false
  }),
  computed: {
    ...mapState(['firestore'])
  },
  mounted () {
    this.$root.$on('showAuthorEditor', (targetAuthor) => {
      this.targetAuthorId = targetAuthor.id
      this.targetAuthorData = JSON.parse(JSON.stringify(targetAuthor))
      delete this.targetAuthorData.id
      this.shown = true
    })
  },
  methods: {
    ...mapMutations(['setGlobalNotification']),
    async updateAuthor () {
      console.log('Update author.')
      try {
        if (this.targetAuthorData.flags) delete this.targetAuthorData.flags
        await updateDoc(doc(this.firestore, 'authors', this.targetAuthorId), this.targetAuthorData)
        this.setGlobalNotification({ val: 'Author updated.' })
      } catch (err) {
        console.log(err)
        this.setGlobalNotification({ val: 'Error updating author.', type: 'error' })
      }
      this.shown = false
    }
  }
}
</script>

<style>

</style>
